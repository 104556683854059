import $ from 'jquery'

class NavigationMain extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this);
    this.bindFunctions();
    this.bindEvents();
  }

  bindFunctions () {
    this.positionSubmenus = this.positionSubmenus.bind(this);
  }

  bindEvents () {
    const self = this;

    // reposition submenus on resize

    var doResize;
    window.onresize = function() {
      clearTimeout(doResize); // rate limit
      doResize = setTimeout(self.positionSubmenus, 100);
    };
  }

  connectedCallback () {
    this.positionSubmenus();
  }

  positionSubmenus (e) {
    $('.menu-submenu', this).each(function(i,o){

      const pWidth = $(o).parent().width();
      const oWidth = $(o).width();

      
      if(oWidth <= pWidth) {
        // if the submenu is less wide than the parent, we just need to stretch it
        $(o).css('min-width', `${pWidth}px`);
      } else {

        // if the submenu is wider, center it with a negative left margin of half the difference
        $(o).css('margin-left', `${(pWidth - oWidth) / 2}px`);
      }
    });
  }
}

window.customElements.define('flynt-navigation-main', NavigationMain, {
  extends: 'nav'
})