import $ from 'jquery'
import 'justifiedGallery/dist/js/jquery.justifiedGallery';
import 'justifiedGallery/dist/css/justifiedGallery.css';

import 'jquery-colorbox/jquery.colorbox';

class GridImages extends window.HTMLDivElement {
  constructor(...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) { }
    return data
  }

  resolveElements () {

  }

  connectedCallback () {
    this.initGrid()
  }

  initGrid () {

    $('#gallery').justifiedGallery({
      rowHeight: 120,
      rel: 'gallery-image', //replace with 'gallery1' the rel attribute of each link
      margins: 10,
      lastRow: 'justify'
    }).on('jg.complete', function () {
      $(this).find('a').colorbox({
        maxWidth: '80%',
        maxHeight: '80%',
        opacity: 0.8,
        transition: 'elastic',
        current: ''
      });
    });

  }
}

window.customElements.define('flynt-grid-images', GridImages, { extends: 'div' })
